import React, { useEffect, useMemo, useState } from 'react';
import {
  Box, Paper, Stack, Typography, Divider, Button, Chip, DialogActions, DialogContent, DialogContentText, DialogTitle, Tooltip,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import { useTheme } from '@emotion/react';
import { useSelector } from 'react-redux';
import { CustomDivider, SelectDropdown, Wrapper } from '../../components';
import { Article, CarbonApi } from '../../assets';
import { Logs, apiCategortList, addApiToVault, postFile, postFileToVaults } from '../../services';
import { Combinator } from './Combinator';
import { keysPair } from '../../services/keysPairService';
import { useLocation, useNavigate } from 'react-router-dom';
import DocumentScannerIcon from '@mui/icons-material/DocumentScanner';
import WalletIcon from '@mui/icons-material/Wallet';
import AddBoxIcon from '@mui/icons-material/AddBox';
import { notification } from 'antd';
import AddToVaultDialog from './addToVaultDialog';
import useMediaQuery from "@mui/material/useMediaQuery";


export function OnscreenApi() {
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();

  const [apiCategoryAndList, setApiCategoryAndList] = useState({});
  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedAPI, setSelectedAPI] = useState('');
  const [queryParamsFlag, setQueryParamsFlag] = useState(false);
  const [apiCredList, setApiCredList] = useState([]);
  const [file, setFile] = useState({});
  const { user } = useSelector((state) => state.auth);
  const userDetails = JSON.parse(sessionStorage.getItem("userDetails"));
  const [jsonData, setJsonData] = useState(null);
  const [image, setImage] = useState({
    error: null,
    uploaded: null,
    masked: null,
    status: null,
  });

  const emailsToCheck = [
    "vinod.kumawat@habilelabs.io",
    "reena@habilelabs.io",
    "babudevatwal1996@gmail.com"
  ];

  const isEmailMatch = useMemo(() => {
    return emailsToCheck?.includes(userDetails?.email);
  }, [userDetails?.email]);
  

  const [categoryChange, setCategoryChange] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await apiCategortList();
        if (res?.status === 200) {
          const categories = res?.data?.data?.attributes?.apisByCategory || {};
          setApiCategoryAndList(categories);
          const sortedCategories = Object.keys(categories).sort();
          const firstCategory = sortedCategories[0] || '';
          setSelectedCategory(firstCategory);
        }
      } catch (error) {
        Logs(error);
      }

      try {
        const response = await keysPair({ id: user?.company?.id });
        const data = response.data.data.map((item) => ({
          id: item.id,
          apiKey: item.attributes.apiKey,
          apiSecret: item.attributes.apiSecret,
          apis: item.attributes?.apis?.data.map(api => api.id)
        }));
        setApiCredList(data);
      } catch (error) {
        console.error('Error fetching API data:', error);
      }
    };

    fetchData();
  }, [user?.company?.id]);

  useEffect(() => {
    if (!categoryChange && selectedCategory && apiCategoryAndList[selectedCategory]?.apis) {
      const sortedApis = apiCategoryAndList[selectedCategory]?.apis.sort((a, b) => a.name.localeCompare(b.name));
      const firstApi = sortedApis[0] || null;
      if (!Array.isArray(apiCredList)) {
        console.error('apiCredList is not an array:', apiCredList);
        return;
      }
      if(firstApi && firstApi.id) {
        setSelectedAPI(firstApi.id); 
        let pickedSecret = apiCredList?.find(cred => cred.apis.includes(firstApi.id)) || null;
        if(pickedSecret) {
          sessionStorage.setItem("apiKey", pickedSecret.apiKey);
          sessionStorage.setItem("apiSecret", pickedSecret.apiSecret);
        } else {
          sessionStorage.setItem("apiKey", "");
          sessionStorage.setItem("apiSecret", "");
        }
      } else {
        setSelectedAPI('');
        sessionStorage.setItem("apiKey", "");
        sessionStorage.setItem("apiSecret", "");
      }
    }
  }, [selectedCategory, apiCredList, categoryChange]);
  
  useEffect(() => {
    if (selectedAPI) {
      const pickedSecret = apiCredList?.find(cred => cred.apis.includes(selectedAPI)) || null;
      if (pickedSecret) { 
        sessionStorage.setItem("apiKey", pickedSecret.apiKey);
        sessionStorage.setItem("apiSecret", pickedSecret.apiSecret);
      } else {
        sessionStorage.setItem("apiKey", "");
        sessionStorage.setItem("apiSecret", "");
      }
    }
  }, [selectedAPI, apiCredList]);

  const getSelectedApiInfo = (apiId) => {
    const categories = Object.values(apiCategoryAndList);
    for (const category of categories) {
      const foundApi = category.apis.find(api => api.id === apiId);
      if (foundApi) {
        return foundApi;
      }
    }
    return null;
  };

  const getSelectedApiId = (apiName) => {
    const categories = Object.values(apiCategoryAndList);
    for (const category of categories) {
      const foundApi = category.apis.find(api => api.name === apiName);
      if (foundApi) {
        return foundApi.id;
      }
    }
    return null;
  };

  useEffect(() => {
    if (location.state) {
      const { selectedCategory, selectedApi } = location.state;
      setSelectedCategory(selectedCategory);
      const apiId = getSelectedApiId(selectedApi);
      setSelectedAPI(apiId);
      setCategoryChange(true);
    }
  }, [location.state, apiCategoryAndList]);

  const handleCategoryChange = (value) => {
    setCategoryChange(false);
    setSelectedCategory(value);
  }


  const { apiOptions, api } = useMemo(() => {
    const selected = apiCategoryAndList[selectedCategory] || [];
    if (selected?.apis?.length > 0) {
      const sortedApiOptions = selected?.apis.map((item) => ({
        label: item.name,
        value: item.id,
      })).sort((a, b) => a.label.localeCompare(b.label));

      const selectedApi = selected.apis.find((x) => x.id === selectedAPI);
      const apiDetails = getSelectedApiInfo(selectedAPI);
      setQueryParamsFlag(apiDetails?.apiInput[0]?.queryParam)

      return { apiOptions: sortedApiOptions, api: selectedApi, apiDetails };
    }
    return { apiOptions: [], api: null, apiDetails: null };
  }, [selectedCategory, apiCategoryAndList, selectedAPI]);

  const handleClick = () => {
    const selectedApi = getSelectedApiInfo(selectedAPI);
    if (selectedApi) {
      navigate('/api-document#api-services', {
        state: {
          selectedSubCategory: selectedApi.name,
          selectedCategory: selectedCategory,
          showBackButton: true
        },
      });
    } else {
      console.error('Selected API not found.');
    }
  };

  const handleAddClick = () => {
    setOpenDialog(true);
  };

  const handleVaultClick = () => {
    navigate("/vault")
  }

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const handleAddConfirm = async () => {
    try {
      const res = await postFile({file: file, number: jsonData?.detectedDetails || jsonData?.detectedFiles || null });
      if (res?.status === 201) {
        notification.success({
          message: 'Success',
          description: 'Successfully added response to vault',
        });
        
        const documentName = file?.name;
        const documentType = file?.type;
        const token = res?.data?.token;
        const response = jsonData?.detectedDetails || jsonData?.detectedFiles || null;
        const apiName = getSelectedApiInfo(selectedAPI)
        if (token) {
          await postFileToVaults({token,documentName,documentType,response,apiName: apiName.name});
        } else {
          console.error('No token received in response:', response);
          notification.error({
            message: 'Error',
            description: 'No token received in response',
          });
        }
      } else {
        console.error('Unexpected response status:', response);
        notification.error({
          message: 'Error',
          description: `Unexpected response status: ${response?.status}`,
        });
      }
    } catch (error) {
      console.error('Error adding API to vault:', error);
      notification.error({
        message: 'Error',
        description: 'Failed to add response to vault',
      });
    } finally {
      setOpenDialog(false);
    }
  };
  
  let creditText = '';
    if (api?.creditConsumptionValue !== undefined) {
      const creditValue = api?.creditConsumptionValue;
      creditText = creditValue > 1 ? `${creditValue} credits per request` : `${creditValue} credit per request`;
    } else {
      creditText = 'credit per request';
    }

    let creditPerAadhaar = '';
    if (api?.consumptionValueForMaskedAadhar !== undefined) {
      const aadhaarValue = api?.consumptionValueForMaskedAadhar;
      creditPerAadhaar = aadhaarValue > 1 ? `${aadhaarValue} credits per aadhaar` : `${aadhaarValue} credit per aadhaar`;
    } else {
      creditPerAadhaar = 'credit per request';
    }

  const isMobile = useMediaQuery("(max-width:899px)");

  const apiStatus = getSelectedApiInfo(selectedAPI)?.apiStatus;
  const apiInstruction = getSelectedApiInfo(selectedAPI)?.apiStatusInstruction;

  return (
    <Wrapper>
      <Paper sx={{ mt: { xs: 10, sm: 12, md: 12 }, mb: 2 }}>
        <Stack
          direction="row"
          sx={{ pt: 2, px: 2, pb: '0.6rem' }}
          spacing={2}
          justifyContent="space-between"
        >
          <Box sx={{display: 'flex', flexDirection: 'row', gap:'10px'}}>
          <Box component="img" src={CarbonApi} sx={{ width: { xs: 'auto',} }} />
          <Typography
            variant="h4"
            sx={{ color: theme.palette.textColor.primary, fontSize: { xs: '1.5rem', md: '2rem' } }}
          >
            Select API
          </Typography>
          </Box>
         { isEmailMatch &&
           <Box sx={{ display: 'flex', flexDirection: 'row', gap: '15px' }}>
              {file && (jsonData || image?.masked) && (
             <Tooltip title="Add to Vault">
              <AddBoxIcon 
                color='primary' 
                sx={{ fontSize: 24, cursor: 'pointer' }} 
                onClick={handleAddClick} 
              />
            </Tooltip>
            )}

           <Tooltip title="Show Vault">
             <WalletIcon 
               color='primary' 
               sx={{ fontSize: 24, cursor: 'pointer' }} 
               onClick={handleVaultClick}
             />
           </Tooltip>
         </Box>
         } 
        </Stack>
        <Divider />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column',sm:'column', md: 'row' }, }} >
        <Box sx={{ display: 'flex', justifyContent: 'space-between', flexDirection: { xs: 'column',sm:'column', md: 'row', }, p: 3, gap: 2 }}>
    <Stack spacing={1} sx={{ width: { xs: '100%', sm:'auto', md: 'auto' } }}>
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey.darken4 }}
            >
        Type of API
      </Typography>
      <SelectDropdown
        menuOptions={Object.keys(apiCategoryAndList)
          .sort()
          .map((category) => ({
            label: category,
            value: category,
          }))}
        value={selectedCategory}
              onChange={(value) => { handleCategoryChange(value); setFile(null); }}
        widthSize={isMobile ? '100%' : '22rem'}
      />
    </Stack>
    <Stack spacing={1} sx={{ width: { xs: '100%', sm:'auto', md: 'auto' } }}> 
            <Typography
              variant="body1"
              sx={{ color: theme.palette.grey.darken4 }}
            >
        APIs
      </Typography>
      <SelectDropdown
        menuOptions={apiOptions}
        value={selectedAPI}
        onChange={setSelectedAPI}
        widthSize={isMobile ? '100%' : '22rem'}
      />
    </Stack>

    <Stack spacing={1} sx={{ alignItems: 'center',justifyContent: 'end', mt: { xs: 2, md: 0 } }}>
      {apiInstruction && (
        <Chip
          icon={<WarningIcon color="error" />}
          label={
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography variant="body1" >
                {apiInstruction}
              </Typography>
            </Box>
          }
          sx={{
            backgroundColor: '#fdecea',
            color: 'black',
            height: '30px',
            width: 'auto',
            padding: '0 8px',
            border: 'none',
            borderRadius: '4px',
            display: 'flex',
            alignItems: 'center',
          }}
        />
      )}
    </Stack>
        </Box>
          <Box 
            sx={{display: 'flex', 
            alignItems: 'flex-end', 
            p:3, 

          }}>
          <Button
            startIcon={<DocumentScannerIcon />}
            variant="outlined"
            onClick={handleClick}
            sx={{width:{ xs: '100%', sm: '100%', md: 'auto' }}}
          >
            Documentation
          </Button>
        </Box>
      </Box>
      </Paper>
      <Paper> 
      <Box
      sx={{
        display: 'flex',
                flexDirection: { xs: 'column', sm:'row', md: 'row' },
        justifyContent: 'space-between',
        alignItems: 'center',
        pt: 2,
        px: 2,
        pb: '0.6rem',
      }}
    >

<Box sx={{ display: 'flex', gap: 2, mb: { xs: 2, md: 0 } }}>
  <Box component="img" src={Article} sx={{ width: 'auto' }} />
  
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    {apiStatus === 'Online' && (
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: 'green',
          }}
        />
    )}
    {apiStatus === 'Offline' && (
        <Box
          sx={{
            width: 10,
            height: 10,
            borderRadius: '50%',
            backgroundColor: 'red',
          }}
        />
    )}

    <Typography
      variant="h4"
      sx={{ color: theme.palette.textColor.primary, fontSize: { xs: '1.5rem', md: '2rem' } }}
    >
      {api?.name}
    </Typography>
  </Box>
</Box>

  <Box
    sx={{
      display: 'flex',
      flexDirection: { xs: 'column', sm: 'row' },
      justifyContent: 'end',
      gap: '15px',
      alignItems: 'center',
    }}
  >
    {api?.apiUrl === '/v1/aadhaar-masking' && api?.consumptionValueForMaskedAadhar !== null && (
      <Typography
        variant="h5"
        sx={{
          color: theme.palette.textColor.blue,
          background: theme.palette.background.light,
          borderRadius: 2,
          px: '12px',
          py: '2px',
        }}
      >
        {creditPerAadhaar}
      </Typography>
    )}

    <Box >
      <Typography
        variant="h5"
        sx={{
          color: theme.palette.textColor.blue,
          background: theme.palette.background.light,
          borderRadius: 2,
          px: '12px',
          py: '2px',
        }}
      >
        {creditText}
      </Typography>
    </Box>
  </Box>
</Box>

        <Divider />
        <Combinator
          queryParamsFlag={queryParamsFlag}
          apiInput={api?.apiInput}
          jsonData={jsonData}
          setJsonData={setJsonData}
          apiResponse={api?.apiResponse}
          apiUrl={api?.apiUrl}
          file={file}
          setFile={setFile}
          image={image}
          setImage={setImage}
        />
      </Paper>
      <AddToVaultDialog
        open={openDialog} 
        handleClose={handleDialogClose} 
        handleConfirm={handleAddConfirm} 
      />
    </Wrapper>
  );
}
