import { useState, useEffect, Fragment } from 'react';
import {
  Box, Stack, Typography, Button, InputLabel, Input
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useTheme } from '@emotion/react';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import { collectiveApiRequest } from '../../services';
import { remainingCredits } from '../../store/reducers/auth';
import { ErrorText } from '../errorText';
import { Cloud } from '../../assets';
import { CloudRight } from '../../assets';
import { totalCreditsAndRemaining } from '../../services/creditsService';
import PdfViewer from '../../utils/pdfViewer/PdfViewer';

export function UploadImage({
  setImage,
  setJsonData,
  setloader,
  apiResponse,
  image,
  upload,
  apiUrl,
  setDownloadedUrl,
  errorObject,
  setErrorObject,
  file,
  setFile,
  setStatus,
  setCurlBody,
  setCurlUrl,
  setCurlMethod,
}) {
  const theme = useTheme();
  const form = useForm({ mode: 'all' });
  const { register, handleSubmit } = form;
  const [previewImage, setPreviewImage] = useState();
  const [openDialog, setOpenDialog] = useState(false);
  const [fileError, setFileError] = useState('');
  const [isPdf, setIsPdf] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const dispatch = useDispatch();

  const { getRootProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      if (acceptedFiles.length > 0) {
        setFile(acceptedFiles[0]);
      }
    },
  });

const handleFileChange = (e, value) => {
  const file = e.target.files[0];
  setFile((prev) => ({
    ...prev,
    [value]: file,
  }));
};


  useEffect(() => {
    if (file) {
      setPageNumber(1);
      const formData = new FormData();
      formData.append('file', file);
      setImage((prev) => ({ ...prev, uploaded: formData }));
      setDownloadedUrl('');
      setFileError('');
      
      setCurlBody(formData);
      setCurlUrl(apiUrl);
      setCurlMethod('POST');
    }
  }, [file, setImage, setDownloadedUrl, apiUrl, setCurlBody, setCurlUrl, setCurlMethod]);

  const handlePreview = (uploadedFile) => {
    if (uploadedFile?.type === 'application/pdf') {
      setPreviewImage(URL.createObjectURL(uploadedFile));
      setIsPdf(true);
      setOpenDialog(true);
    } else {
      setPreviewImage(URL.createObjectURL(uploadedFile));
      setIsPdf(false);
      setOpenDialog(true);
    }
  };
  

  const handleApiRequest = async () => {
    setloader(true);
    setErrorObject(null);

    const allowedTypes = ['image/png', 'image/jpeg', 'image/jpg', 'application/pdf'];
    Object.entries(file).forEach(([id, file]) => {
      if (!file) {        
        setFileError("No file selected");
        setloader(false);
        return;
      }
  
      if (!allowedTypes.includes(file?.type)) {
        setFileError(
          `Invalid file type. Please upload a .png, .jpg, .jpeg, or .pdf file.`
        );
        setloader(false);
        return;
      }
    });
    const formData = new FormData();

    if (file && typeof file === 'object' && !Array.isArray(file)) {
      Object.entries(file).forEach(([value, fileItem]) => {
        formData.append(`${value}`, fileItem);
      });
    } else if (file) {
      formData.append(`file`, file);
    }

    try {
      const res = await collectiveApiRequest(formData, apiUrl);
      setStatus(res?.status);
      if (res?.status === 200) {
        if (apiResponse === 'json') {
          setJsonData(res?.data);
        } else {
          const downloadUrl = res?.data?.downloadUrl;
          const status = res?.data?.status;
          setDownloadedUrl(downloadUrl);
          setImage((prev) => ({ ...prev, masked: downloadUrl, status }));
        }

        setTimeout(async () => {
          const remainingCredit = await totalCreditsAndRemaining();
          if (remainingCredit?.status === 200) {
            dispatch(
              remainingCredits({
                credits: remainingCredit?.data?.credit?.remainingCredit,
              }),
            );
            setloader(false);
          }
        }, 2000);
      }
    } catch (error) {
      setloader(false);
      setErrorObject(error?.response?.data);
    }
  };

  const files = (
    <li key={file?.name}>
      {file?.name}
      {' '}
      -
      {Math.round((file?.size) / 1000)}
      {' '}
      kB
    </li>
  );

  useEffect(() => {
    setErrorObject(null);
    setDownloadedUrl('');
  }, [apiResponse, setErrorObject]);

  useEffect(() => {
    if (errorObject) {
      if (apiResponse === 'json') {
        setJsonData(errorObject?.response?.data || errorObject?.error || errorObject);
      } else {
        setImage(() => ({ error: errorObject || errorObject?.error || errorObject?.response?.data }));
      }
    } else {
      setImage((prev) => ({ ...prev, error: null }));
    }
  }, [errorObject, setJsonData, setImage, apiResponse]);

  const isFileUploaded = file === null 
  ? false 
  : Object.keys(file)?.length === upload?.length;

  return (
    <>
    <Stack
      component="form"
      onSubmit={handleSubmit(handleApiRequest)}
      spacing={2}
      sx={{ px: { xs: 2, md: 0 }, py: 2 }}
    >
      {upload?.map((item) => (
  <Fragment key={item.id}>
    <Typography
      variant="body1"
      sx={{ padding: "3px 0", color: theme.palette.grey.darken4 }}
    >
      {item.label}
    </Typography>
    <Box
      sx={{
        border: `1px dashed ${theme.palette.textColor.grey}`,
        minHeight: '120px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
      }}
    >
      <InputLabel
        htmlFor={`upload-image-${item.id}`}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          height: '100%',
          cursor: 'pointer',
        }}
        {...getRootProps({ className: 'dropzone' })}
        onClick={(e) => {
          e.preventDefault();
          document.getElementById(`upload-image-${item.value}`).click();
        }}
      >
        <Box
          component="img"
          src={file && file[item?.value] ? CloudRight : Cloud}
        />
        <Typography
          variant="h5"
          sx={{
            color: theme.palette.textColor.grey,
            whiteSpace: 'break-spaces',
            textAlign: 'center',
          }}
        >
          {file && file[item.value]
            ? 'File uploaded'
            : 'Drag and drop files here or Choose file'}
        </Typography>
        {file && file[item.value] && (
          <div>{file[item?.value]?.name}</div>
        )}
      </InputLabel>
      <Input
        id={`upload-image-${item.value}`}
        type="file"
        style={{ display: 'none' }}
        onChange={(e) => handleFileChange(e, item.value)}
      />
    </Box>
    {file && file[item.value] && (
      <Button
        onClick={() => handlePreview(file[item.value])}
        variant="outlined"
        sx={{ mt: 1 }}
      >
        Preview
      </Button>
    )}
    {item.fieldInstruction && (
      <Typography
        variant="body1"
        sx={{ color: theme.palette.textColor.grey, marginTop: '5px' }}
      >
        {item.fieldInstruction}
      </Typography>
    )}
  </Fragment>
))}


        {fileError && <ErrorText>{fileError}</ErrorText>}

        <Stack
          direction={{ xs: 'column', md: 'row' }}
          spacing={{ xs: 1, md: 2 }}
          sx={{ pt: 2 }}
        >
          {/* <Button
            onClick={handlePreview}
            variant="outlined"
            disabled={!isFileUploaded}
            sx={{ width: { xs: '100%', md: 'auto' }, mt: { xs: 1, md: 0 } }}
          >
            Preview
          </Button> */}
          <Button
            variant="contained"
            type="submit"
            disabled={!isFileUploaded}
            sx={{ width: { xs: '100%', md: 'auto' } }}
          >
            Send
          </Button>
        </Stack>
      </Stack>

      <PdfViewer
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        isPdf={isPdf}
        previewImage={previewImage}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        useDialog
      />
    </>
  );
}
