import * as React from "react";
import {
  Box,
  Drawer,
  ListItemButton,
  ListItemText,
  Typography,
  ListItem,
  List,
  Collapse,
  IconButton,
  Divider,
  Button,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { getApiDocsData } from "../../services/apiDocsService";
import { hasChildren } from "../../pages/dashboard/utils";
import { menu } from "./menu";
import DescriptionBox from "./DescriptionBox";
import APIServices from "./APIServices";
import { SidebarOpen, SidebarClose } from "../../assets";
import { useLocation, useNavigate } from "react-router-dom";
import jsPDF from 'jspdf';

const drawerWidth = 280;

export const APIDocument = () => {
  const location = useLocation();
  const [data, setData] = React.useState(null);
  const [selectedDrawerItem, setSelectedDrawerItem] = React.useState(null);
  const [showBackButton, setShowBackButton] = React.useState(null);
  const [selectedCategory, setSelectedCategory] = React.useState(null);
  const [selectedSubCategory, setSelectedSubCategory] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const [openAll] = React.useState(false);

  const isTablet = useMediaQuery("(max-width:899px)");

  const httpStatusCodeTableColumns = [
    { header: "Code", field: "code" },
    { header: "Name", field: "name" },
    { header: "Explanation", field: "explanation" },
  ];

  const httpStatusCodeTableRows = data?.httpStatusCode?.codeData?.map(
    (item) => ({
      code: item.code,
      name: item.name,
      explanation: item.explanation,
    })
  );

  const glossaryColumns = [
    { header: "Name", field: "name" },
    { header: "Explanation", field: "explanation" },
  ];

  const glossaryRows = data?.glossary?.codeData?.map((item) => ({
    name: item.name,
    explanation: item?.explanation,
  }));
  React.useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getApiDocsData();
        setData(res?.data?.data?.attributes);

        if (res?.data?.data?.attributes?.apiServices?.length > 0) {
          const firstCategory = res.data.data.attributes.apiServices[0];
          setSelectedCategory(firstCategory.categoryName);

          if (firstCategory.apiDetails.length > 0) {
            setSelectedSubCategory(firstCategory.apiDetails[0].APIDetail.title);
          }
        }
      } catch (error) {
        console.error("Error fetching API data:", error);
      }
    };

    fetchData();
  }, []);

  const apiServicesData = data?.apiServices?.map((service) => ({
    apiDetails: service?.apiDetails?.map((detail) => ({
      category: service?.categoryName,
      title: detail?.APIDetail?.title,
      description: detail?.APIDetail?.description,
      endpoint: detail?.endpoint,
      method: detail?.apiMethod,
      headerTitle: detail?.header?.title,
      headerDescription: detail?.header?.description,
      headerValue: detail?.header?.codeData?.map((headerItem) => ({
        name: headerItem?.name,
        explanation: headerItem?.explanation,
      })),
      requestParameterTitle: detail?.requestParameter?.title,
      requestParameterDescription: detail?.requestParameter?.description,
      requestParameterValue: detail?.requestParameter?.codeData?.map(
        (paramItem) => ({
          name: paramItem?.name,
          explanation: paramItem?.explanation,
        })
      ),
      requestSample: detail?.requestSample,
      responseSample: detail?.responseSample?.map((responseItem) => ({
        code: responseItem?.code,
        description: responseItem?.description,
        json: responseItem?.json,
      })),
    })),
  }));

  const introductionRef = React.useRef(null);
  const authenticationRef = React.useRef(null);
  const httpStatusCodeRef = React.useRef(null);
  const rateLimitRef = React.useRef(null);
  const apiServicesRef = React.useRef(null);
  const glossaryRef = React.useRef(null);
  const scrollToPosition = (ref) => {
    if (ref?.current) {
      const { top } = ref.current.getBoundingClientRect();
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const targetOffset = isTablet ? top + scrollTop - 100 : top + scrollTop - 60;
      window.scrollTo({ top: targetOffset, behavior: "smooth" });
    }
  };

  React.useEffect(() => {
    if (location?.hash) {
      const section = location.hash.substring(1);
      if(section === 'api-services'){
        scrollToPosition(apiServicesRef)
      }
    }
  }, [location.hash, data])

  React.useEffect(() => {
    if (location.state) {
      const { selectedCategory, selectedSubCategory, showBackButton } = location.state;
      setSelectedCategory(selectedCategory);
      setSelectedSubCategory(selectedSubCategory);
      setShowBackButton(showBackButton || false);
    }
  }, [location.state, data]);

  const scrollToSection = (section) => {
    switch (section) {
      case "Introduction":
        scrollToPosition(introductionRef);
        break;
      case "Authentication":
        scrollToPosition(authenticationRef);
        break;
      case "HTTP Status Codes":
        scrollToPosition(httpStatusCodeRef);
        break;
      case "Rate Limit":
        scrollToPosition(rateLimitRef);
        break;
      case "Fintech API Services":
        scrollToPosition(apiServicesRef);
        break;
      case "Glossary":
        scrollToPosition(glossaryRef);
        break;
      default:
        break;
    }
  };

  const handleDrawerItemClick = (item) => {
    setSelectedDrawerItem(item.title);
    scrollToSection(item.title);
    setDrawerOpen(!drawerOpen)
  };

  const navigate = useNavigate();


  const dropdownOptions =
    data?.apiServices?.map((item) => ({
      label: item.categoryName,
      value: item.categoryName,
      subCategories: item.apiDetails.map((detail) => detail.APIDetail.title),
    })) || [];

  function Drawerr() {
    return menu.map((item, key) => <MenuItem key={key} item={item} />);
  }

  const createApiDocumentPdf = () => {
    const doc = new jsPDF();
    let currentY = 20;
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 10;
    const maxWidth = pageWidth - margin * 2;
    
    const greyColor = [30, 29, 28]; 
  
    const addTitleWithLine = (text, x, y, fontSize = 16, color = [0, 112, 192]) => {
      if (text) {
        doc.setFontSize(fontSize);
        doc.setTextColor(...color);
        doc.text(text, x, y);
        doc.setDrawColor(200);
        doc.line(x, y + 2, pageWidth - margin, y + 2); 
        currentY = y + 12; 
        doc.setTextColor(...greyColor); 
      }
    };
  
    const addParagraphText = (text, x, y, fontSize = 12) => {
      if (text) {
        doc.setFontSize(fontSize);
        doc.setTextColor(...greyColor);
        const lines = doc.splitTextToSize(String(text), maxWidth);
        let newY = y;
        if (newY + lines.length * 8 > pageHeight - margin) {
          doc.addPage();
          newY = margin;
          currentY = margin;
        }
        doc.text(lines, x, newY);
        currentY = newY + lines.length * 8 + 2;
      }
    };
  
    addTitleWithLine(data?.Introduction.title, margin, currentY);
    addParagraphText(data.Introduction.description, margin, currentY + 1);
  
    addTitleWithLine(data.Authentication.title, margin, currentY + 2);
    addParagraphText(data.Authentication.description, margin, currentY + 1);
  
    addTitleWithLine(data?.httpStatusCode?.title, margin, currentY + 2);
    doc.autoTable({
      startY: currentY + 1,
      head: [['Code', 'Name', 'Explanation']],
      body: data.httpStatusCode.codeData.map(item => [
        item.code || '',
        item.name || '',
        item.explanation || ''
      ]),
      margin: { left: margin },
      headStyles: {
        fillColor: [0, 112, 192],   
        textColor: [255, 255, 255],
      },
    });
    currentY = doc.autoTable.previous.finalY + 10;
  
    addTitleWithLine(data.rateLimit.title, margin, currentY + 2);
    addParagraphText(data.rateLimit.description, margin, currentY + 1);
    currentY += 10;
  
    addTitleWithLine('API Services', margin, currentY);
  
    data?.apiServices?.forEach((service) => {
      addParagraphText(service.categoryName, margin, currentY, 16);
  
      service.apiDetails.forEach((apiDetail) => {
// Add background for apiDetail?.apiName
// Add full-line background for apiDetail?.apiName
if (apiDetail?.apiName) {
  const text = apiDetail.apiName;
  const fontSize = 14;
  const backgroundHeight = fontSize * 0.6; // Adjust for font size
  const backgroundY = currentY - backgroundHeight + 2; // Adjust Y position for padding

  // Draw full-width background rectangle
  doc.setFillColor(0, 112, 192); // Blue background color
  doc.rect(margin, backgroundY, pageWidth - margin * 2, backgroundHeight, 'F');

  // Add text over the background
  doc.setFontSize(fontSize);
  doc.setTextColor(255, 255, 255); // White text color
  doc.text(text, margin + 2, currentY);

  // Update currentY
  currentY += backgroundHeight + 6; // Add spacing after the section
  doc.setTextColor(...greyColor); // Reset text color for further text
}

// Add title with line

      
        addTitleWithLine("Description",  margin, currentY, 12)
        addParagraphText(`${apiDetail.APIDetail.description}`, margin, currentY);

        addTitleWithLine("Endpoint: ", margin, currentY , 12);
        addParagraphText(`${apiDetail.endpoint}`, margin, currentY);

        addTitleWithLine("Method: ", margin, currentY , 12);
        addParagraphText(`${apiDetail.apiMethod}`, margin, currentY);

        currentY += 2;
      
        if (apiDetail.header) {
          addTitleWithLine("Headers:", margin, currentY, 12);
          doc.autoTable({
            startY: currentY - 5,
            head: [['Name', 'Explanation']],
            body: apiDetail.header.codeData.map(item => [
              item.name || '',
              item.explanation || ''
            ]),
            margin: { left: margin },
            headStyles: {
              fillColor: [0, 112, 192],   
              textColor: [255, 255, 255],
            },
          });
          currentY = doc.autoTable.previous.finalY + 7;
        }
      
        if (apiDetail.requestParameter) {
          addTitleWithLine("Request Parameters:", margin, currentY, 12);
          doc.autoTable({
            startY: currentY - 5,
            head: [['Name', 'Explanation']],
            body: apiDetail.requestParameter.codeData.map(item => [
              item.name || '',
              item.explanation || ''
            ]),
            margin: { left: margin },
            headStyles: {
              fillColor: [0, 112, 192],   
              textColor: [255, 255, 255],
            },
          });
          currentY = doc.autoTable.previous.finalY + 7;
        }
      
        if (apiDetail.responseSample.length > 0) {
          addTitleWithLine("Response Samples:", margin, currentY, 12);
          apiDetail.responseSample.forEach((sample, idx) => {
            addParagraphText(`Code: ${sample.code || ''} - ${sample.description || ''}`, margin, currentY);
            addParagraphText(JSON.stringify(sample.json, null, 2), margin, currentY);
          });
        }
      });
      
    });
  
    addTitleWithLine(data.glossary.title, margin, currentY + 10);
    doc.autoTable({
      startY: currentY + 2,
      head: [['Name', 'Explanation']],
      body: data.glossary.codeData.map(item => [
        item.name || '',
        item.explanation || ''
      ]),
      margin: { left: margin },
     
      headStyles: {
        fillColor: [0, 112, 192],   
        textColor: [255, 255, 255],
      },
    
    });
    
    doc.save('API_Documentation.pdf');
  };
  
  
  
  
  
  const SingleLevel = ({ item }) => {
    
    return (
      <>
          <ListItem button onClick={() => handleDrawerItemClick(item)}>
            <ListItemText>
              <Typography
                variant="body2"
                fontSize={18}
                style={{
                  color: selectedDrawerItem === item.title ? "#1976d2" : "inherit",
                }}
                lineHeight="15px"
              >
                {item.title}
              </Typography>
            </ListItemText>
          </ListItem>
      </>
        );
      };
      const MenuItem = ({ item }) => {
        const Component = hasChildren(item) ? MultiLevel : SingleLevel;
        return <Component item={item} />;
      };
    
      const MultiLevel = ({ item }) => {
        const [openSub, setOpenSub] = React.useState(false);
    
        const handleSubOpenClick = (category, subCategory) => {
          // setOpen(open);
          setOpenSub(!openSub);
          scrollToPosition(apiServicesRef);
          setSelectedCategory(category);
          setSelectedSubCategory(subCategory);
        };
    
        const handleSubCategoryClick = (category, subCategory) => {
          scrollToPosition(apiServicesRef);
          setSelectedCategory(category);
          setSelectedSubCategory(subCategory);
          setDrawerOpen(!drawerOpen)
    };
          return (
            <>
              <ListItem button sx={{ pl: 0 }}>
                <ListItemButton
                  onClick={() => handleDrawerItemClick(item)}
                  style={{ background: "transparent" }}
                >
                  <ListItemText>
                    <Typography
                      variant="body2"
                      fontSize={18}
                      style={{
                        color:
                          selectedDrawerItem === item.title ? "#1976d2" : "inherit",
                      }}
                      lineHeight="15px"
                    >
                      {item.title}
                    </Typography>
                  </ListItemText>
                </ListItemButton>
              </ListItem>
              <List component="div">
                {dropdownOptions.map((option, index) => (
                  <React.Fragment key={index}>
                    <ListItem button onClick={() => handleSubOpenClick(option.value)}>
                      <ListItemText>
                        <Typography
                          variant="body2"
                          fontSize={16}
                          style={{
                            color:
                              selectedCategory === option.value
                                ? "#1976d2"
                                : "#555555",
                          }}
                          lineHeight="15px"
                        >
                          {option.label}
                        </Typography>
                      </ListItemText>
                      {openSub ? (
                        <KeyboardArrowRightIcon fontSize="large" />
                      ) : (
                        <KeyboardArrowDownIcon fontSize="large" />
                      )}
                    </ListItem>
                    <Collapse in={!openSub} timeout="auto" unmountOnExit>
                      {selectedCategory === option.value &&
                        option.subCategories.map((subCategory, subIndex) => (
                          <ListItem
                            button
                            key={subIndex}
                            sx={{ pl: 3 }}
                            onClick={() =>
                              handleSubCategoryClick(option.value, subCategory)
                            }
                          >
                            <ListItemButton style={{ background: "transparent" }}>
                              <ListItemText>
                                <Typography
                                  variant="body2"
                                  fontSize={16}
                                  style={{
                                    color:
                                      selectedSubCategory === subCategory
                                        ? "#1976d2"
                                        : "#555555",
                                  }}
                                  lineHeight="15px"
                                >
                                  {subCategory}
                                </Typography>
                              </ListItemText>
                            </ListItemButton>
                          </ListItem>
                        ))}
                    </Collapse>
                  </React.Fragment>
                ))}
              </List>
            </>
          );
        };

  return (
    <>
  
  <Box sx={{ display: { sm: 'flex' }, background: "#ffffff" }}>
        {isTablet && (
          <IconButton
            color="inherit"
            aria-label="open-drawer"
            edge="start"
            onClick={() => setDrawerOpen(!drawerOpen)}
            sx={{
              position: "fixed",
              top: "55px",
              left: "0",
              borderRadius: "0px",
              marginLeft: "0",
              padding: "8px 6px 8px 24px;",
              background:'#FAFAFA',
              alignItems:'flex-start',
              justifyContent: 'flex-start',
              zIndex: '1201',
              borderBottom: '1px solid #E0E0E0',
              right:'0',
              '&:hover': {
                backgroundColor: '#FAFAFA',
                      },
                    }}
                  >
            <Box
            component="img"
            sx={{ maxWidth: '100%', height: '100%' }}
            src={drawerOpen ? SidebarOpen : SidebarClose  }
              alt="Drawer Icon"
            />
          </IconButton>
        )}
        <Drawer
        variant={isTablet ? "temporary" : "permanent"}
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        style={{ width: drawerWidth }}
        sx={{
          display: { xs: "block", sm: "block" },
          "& .MuiDrawer-paper": {
            boxSizing: "border-box",
            width: drawerWidth,
            zIndex: 0,
            paddingTop: {xs: "0px", md:"10px", lg: "70px"},
            top:{xs:'91px', lg:'0px'},
            boxShadow:'none',
            overflowY: 'auto',
            WebkitOverflowScrolling: 'touch',
            height: '100%',
          },
          "& .MuiModal-backdrop": {
            top:'91px',
          },
          
        }}

      >
      {isTablet && <Divider sx={{marginTop:"0px"}}/>}
      
        <Box
          component="div"
          sx={{
            width: { sm: drawerWidth },
            flexShrink: { sm: 0 },
            overflowY: 'auto',
            height: 'calc(100vh - 100px)',
          }}
          aria-label="mailbox folders"
        >
          {Drawerr()}
        </Box>
      </Drawer>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          marginTop:{xs:'91px',md:9}
        }}
      >


        <Box sx={{display: 'flex', justifyContent: 'end', paddingRight: '15px'}}>
          <Button onClick={createApiDocumentPdf} variant="contained">
            Download PDF
          </Button>

        </Box> 

          <div id="api-document-content">
          <DescriptionBox
          title={data?.Introduction?.title}
          refProp={introductionRef}
          description={data?.Introduction?.description}
        />

        {/* Authentication */}

        <DescriptionBox
          title={data?.Authentication?.title}
          refProp={authenticationRef}
          description={data?.Authentication?.description}
        />

        {/* HTTP Status Code */}

        <DescriptionBox
          title={data?.httpStatusCode?.title}
          refProp={httpStatusCodeRef}
          description={data?.httpStatusCode?.description}
          showBasicTable
          columns={httpStatusCodeTableColumns}
          rows={httpStatusCodeTableRows}
        />

        {/* Rate Limit */}

        <DescriptionBox
          title={data?.rateLimit?.title}
          refProp={rateLimitRef}
          description={data?.rateLimit?.description}
        />

        {/* API Services */}

        <APIServices
          refProp={apiServicesRef}
          apiServicesData={apiServicesData}
          selectedCategory={selectedCategory}
          selectedSubCategory={selectedSubCategory}
          showBackButton={showBackButton}
          openAll={openAll}
        />

        {/* Glossary */}

        <DescriptionBox
          title={data?.glossary?.title}
          refProp={glossaryRef}
          description={data?.glossary?.description}
          showBasicTable
          columns={glossaryColumns}
          rows={glossaryRows}
        />
          </div>
        </Box>
      </Box>
    </>
  );
};
